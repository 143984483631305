
import C3Fa from "../images/products/pico2.png";
import iVa from "../images/products/iVA.png";
import G24K from "../images/products/G24K-Enterprise-02.png";


export const products =[
    {
        name:"Intelligent Vision Analyzer - plus (iVA+)",
        description: " Virtual reality (VR) based Visual Field Perimeter, designed for Visual Field test. iVA+ is easy to carry and offers ease of use for both doctors and patients. It conducts visual field tests as per International standards",
        image:iVa,
        link:"/product/iva",
        label:{
            visible: false,
        }

    },
    {
        name:"C3 Field Analyser (C3FA)",
        description: "World’s  first VR based visual field perimeter developed by Alfaleus in association with Remidio.  It was clinically validated at Aravind Eye Hospital, and launched in feb 2019. C3FA was replaced by more advanced version - Intelligent Vision Analyser (IVA). As of now, C3FA has been discontinued, how ever we continue to provide customer service support.",
        image:C3Fa,
        link:"/product/c3fa",
        label:{
            visible: true,
            backgroundColor:"black",
            textcolor:"white",
            mainText:"Legacy Product",
            subText:"*Discontinued",
            subTextColor:"#ccc",
        }

    },
    {
        name:"Open Low Vision Assist (LVA)",
        description: " Virtual Reality (VR) based and clinically validated low vision assistive aid that helps in improving the visual acuity of low vision patients thereby helping them in their day to day activities.",
        image:G24K,
        link:"/product/openlva",
        label:{
            visible: true,
            backgroundColor:"#ABFFFB",
            textcolor:"black",
            mainText:"Launching Soon!",
        }

    }
]