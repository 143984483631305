import React, { useEffect, useState } from "react";
import { Layout } from "../../layout/Layout";
import { FeatureCardCarousel, ProductVideos, Table, TR } from "../Components";
import { MyHero } from "./IVA";
import { Loader } from "../Loader";

export const C3FA = () => {
  const data = [
    [
      {
        title: "Portable",
        body: "C3FA is extremely compact and comfortable at utility, doesen’t expect a posture from the patient thereby simplifying the whole screening process. ",
      },
      {
        title: "Super Fast",
        body: "The device enables any facility to complete the screening process in 6 minutes, thereby enhancing it’s overall efficiency.",
      },
      {
        title: "inCloud Screening",
        body: "Our incloud screening process powered by advanced algorithms ensure better reliability and easy recovery of reports.",
      },
    ],
    [
      {
        title: "No Skilled Labour",
        body: "The device can be operated by anybody. Also doesn't require dedicated infrastructure or maintenance.",
      },
      {
        title: "Affordable",
        body: "The whole setup comes at a price that’s 1/10th of a conventional device. Also cits overheads such as necessary defect and more.",
      },
      {
        title: "Unmatched Accuracy",
        body: "CFA posts for 85% reliability, a number that no other international entity (in VR) has touched. This is made possible with powerful algorithms.",
      },
    ],
  ];
  const label = {
    visible: false,
    backgroundColor: "black",
    textcolor: "white",
    mainText: "Legacy Product",
    subText: "* Discontinued",
    subTextColor: "#ccc",
  };

  // const smdata = [];
  // data.map((slide)=>slide.map(slide=>smdata.push(slide)));
  const [laoding, setlaoding] = useState(true);

  useEffect(() => {
    setlaoding(false);
  }, []);

  return (
    <>
      {laoding ? (
        <Loader />
      ) : (
        <Layout
          image={true}
          brochure={""}
          slider={false}
          sliderTitle="Publications & Presentation"
        >
          <MyHero
            buttons={false}
            title={`Feild Analyzer (C3FA)`}
            typewriter={["Compact", "Comfortable", "Cost Effective"]}
            body={
              "A design perfected over the years by a team of experts, C3FA offers state of the art visual field testing. Wirelessly administer and monitor the test via a tablet to store and manage your reports on the cloud for effortless data access, and offer fast screening services with the click of a button"
            }
            label={label}
          />
          <FeatureCardCarousel data={data} carousalId="featureCarousal" />
          <ProductVideos
            title="Our Product Videos"
            link="https://www.youtube.com/embed/QDwHqjVmYDQ"
          />

          <Table title="Technical Specifications">
            {techspecs.map(({ title, body }, ind) => (
              <TR title={title} key={ind}>
                {body}
              </TR>
            ))}
          </Table>
        </Layout>
      )}
    </>
  );
};

const techspecs = [
  { title: "Model / Part no.", body: "C3 Field Analyzer – C3FA" },
  { title: "Data storage", body: "16 GB | 32 GB | 64 GB" },
  { title: "Stimulus duration", body: "200 ms" },
  { title: "Stimulus interval", body: "1200-1500 ms" },
  { title: "Background illumination", body: "10cd/m2 (31.5 ASB)" },
  {
    title: "Reliability Parameters",
    body: "Fixation loss, False +ve , False –ve",
  },
  { title: "Stimulus colour", body: "White on White" },
  { title: "Method of Presentation", body: "LCD screen" },
  {
    title: "Screening Test Strategies",
    body: "CFA supra threshold Smart Screening",
  },
  { title: "Threshold Test Strategies", body: "Modified Zest, Full Threshold" },
  { title: "Screening Test Modes", body: "Single Intensity" },
  { title: "Central patterns", body: "10-2 | 24-2 | 30-2" },
  { title: "Fixation Monitoring", body: "Heijl-Krakau" },
  { title: "Device Weight", body: "~750 gms" },
  { title: "Dimensions", body: "110 x 150 x 100" },
  { title: "Battery", body: "up to 3 hours " },
  { title: "Analysis Software", body: "C3 Field View" },
  { title: "Operator Interface", body: "Touch screen phone/ tablet" },
  { title: "Electrical requirements", body: "Runs on DC power" },
  { title: "Maximum intensity", body: "1200 ASB" },
  { title: "Max. Temporal Range", body: "30 degrees" },
  { title: "Database management", body: "CFA portal Easy print© software" },
];
