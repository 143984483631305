import React, { useState } from "react";
import { Layout } from "../../layout/Layout";
import "../../assets/css/about.css";
import pico from "../../assets/images/products/iVA.png";
import { AccordianDynamic, SectionHeading, Table, TR } from "../Components";
import brochure from "../../assets/brochure/IVA+ brochure screen.pdf";
import { Link } from "react-router-dom";
import { ivaFeatures } from "../../assets/json/ivaFeatrues";
import NumberScroller from "react-number-scroller";
import sampleReport from "../../assets/images/10.jpg";
import facts from "../../assets/images/ivaFacts.png";
import factsSm from "../../assets/images/ivaFactsSm.png";
import specs from "../../assets/images/ivaSpecs.png";

export const IVAPlus = () => {
  function importAll(r) {
    let images = [];
    r.keys().map((item) => images.push({ [item.replace("./", "")]: r(item) }));
    return images;
  }
  const images = importAll(
    require.context(
      "../../assets/images/sampleReport/",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );

  const featureCard = [
    {
      title: "Fast and accurate",
      description:
        "iVA+ has real-time gaze tracking, thus ensuring minimal fixation loss and more accurate reporting. One can choose to automatically pause the test if the user is not fixating properly at centre.",
    },
    {
      title: "Easy interpretation",
      description:
        "iVA+ reports are designed as per globally accepted format. Tests are designed as per Goldmann perimeter standards.",
    },
    {
      title: "Space saving",
      description:
        "Testing on iVA+ does not require a dedicated dark room. Tests can be conducted anywhere and it can fit easily on a shelf. It can be carried in a small case for outreach programmes",
    },
    {
      title: "Telemedicine ready",
      description:
        "iVA+ can perform tests and save reports without an active internet connection. It has an inbuilt battery and can run without continuous power supply. Thus making it ideal for telemedicine applications.",
    },
    {
      title: "Easy to operate",
      description:
        "iVA+ has a simple smartphone interface for doctors and technicians, as well as an intuitive patient interface. It has very low maintenance requirements.",
    },
    {
      title: "Patient comfort",
      description:
        "iVA+ offers ultimate comfort for users. It does not require an eye patch, and one can slit in a comfortable posture while taking the test. The accuracy will not be affected by head movements.",
    },
  ];
  const collabTooltip = [
    ["All India Institute of Medical Science"],
    ["Aravind Eye Hospital, Pondicherry"],
    ["Indian Institure of Technology , Hyderabad"],
    ["Department of Science and technology"],
    ["Ministry of Electronics and Information Technology"],
    ["Startup India"],
    ["Vellore Institure of Technology"],
    ["Birac"],
    ["Invest India"],
    ["Remidio"],
  ];
  const publicationImages = importAll(
    require.context(
      "../../assets/images/publications/",
      false,
      /\.(png|jpe?g|svg)$/
    )
  ).map((img, ind) => {
    return {
      img,
      tooltip: collabTooltip[ind],
    };
  });

  return (
    <Layout image={true} brochure={brochure} slider={false} sliderTitle="">
      <div className="container-fluid p-5 " id="aboutHero">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-md-5 my-3 text-center text-md-start ">
            {/* <img src={logo} alt="" className="img-fluid mb-3" /> */}
            <p className="display-5 fw-bolder text-center text-md-start ">
              Intelligent Vision Analyzer Plus (iVA+)
            </p>
            <p className="fs-5 pe-3 pt-3 text-center text-md-start ">
              Virtual reality (VR) based Visual Field Perimeter, designed for
              Visual Field test. iVA+ is easy to carry and offers ease of use
              for both doctors and patients. It conducts visual field tests as
              per International standards
            </p>
            <Link
              to="/contact"
              className="btn text-white  rounded-pill px-4 "
              style={{ backgroundColor: "#255E8B" }}
            >
              Book a Demo
            </Link>
            <br />

            <a
              href={brochure}
              download
              className="btn  rounded-pill btn-primary-outline btn-outline-light mt-3 px-3"
              style={{ color: "#255E8B", border: "1px solid #255E8B" }}
            >
              <i className="fas fa-file-download mx-3"></i>
              Brochure
            </a>
          </div>
          <div className="col-md-6 p-3 text-end">
            <img
              src={pico}
              className="img-fluid"
              alt=""
              style={{
                maxHeight: "350px",
              }}
            />
          </div>
        </div>
      </div>

      <SectionHeading title="Product Features" />

      <div className="container">
        <div className="row px-3">
          {featureCard.map((card, ind) => (
            <div className="col-lg-4 my-3" key={ind}>
              <FeatrueCard title={card.title}>{card.description}</FeatrueCard>
            </div>
          ))}
        </div>
      </div>

      <IvaProductVideos title="Impact We Created" />

      <Report images={images}/>
      {/* facts */}

      <img src={specs} className="img-fluid bg-white w-100 " alt="" />
      {/* facts 2  */}
      <img src={facts} className="img-fluid d-none d-lg-block" alt="" />
      <img src={factsSm} className="img-fluid d-lg-none" alt="" />
      {/* Features */}
      <Table title="Technical Specifications">
        {ivaFeatures.map((row, ind) => (
          <TR key={ind} title={row.head}>
            {row.data}
          </TR>
        ))}
      </Table>
      {/* Features end */}

      <AccordianDynamic
        title="Clinical Publications"
        id="supportAccordian"
        images={publicationImages}
        childId="collapseThree"
        txt={[]}
      />
    </Layout>
  );
};

const FeatrueCard = (props) => {
  return (
    <>
      <div className="card  shadow h-100 p-4   rounded-25 ">
        <p className="mt-3 ms-3 fw-bold fs-4 text-blue">{props.title}</p>
        <p className="mb-3 ms-3 ">{props.children}</p>
      </div>
    </>
  );
};

const IvaProductVideos = (props) => {
  return (
    <>
      <div className="container-fluid my-5 ">
        <div className="row mt-4 text-center bg-sky py-5">
          <div className="col-lg-4 text-break my-4 py-3 ">
            <h2 className=" display-6 text-blue fw-bold mb-0 pb-0 text-center">
              <NumberScroller to={50000} from={49500} timeout={1} /> +
            </h2>
            <h4
              style={{
                color: "#0F2F48",
              }}
              className=" fs-2  text-center w-100 "
            >
              Lives Impacted
            </h4>
          </div>
          <div className="col-lg-4 text-break my-4 py-3">
            <h2 className=" display-6 text-blue fw-bold mb-0 pb-0">
              <NumberScroller to={150} timeout={1000} /> +
            </h2>
            <h4
              style={{
                color: "#0F2F48",
              }}
              className=" fs-2  text-center w-100 "
            >
              Trusted Doctors
            </h4>
          </div>
          <div className="col-lg-4 text-break my-4 py-3">
            <h2 className="text-center  display-6 text-blue fw-bold mb-0 pb-0">
              <NumberScroller to={4} timeout={1000} /> +
            </h2>
            <h4
              style={{
                color: "#0F2F48",
              }}
              className=" fs-2  w-100"
            >
              Countries
            </h4>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-12 d-flex justify-content-center align-items-center">
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/MPbURIU4H8k?controls=0"
              title="YouTube video player"
              allowFullScreen
              className="rounded"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

const Report = (props) => {
  const [image, setImage] = useState(0);
 
  return (
    <>
      <SectionHeading title="Familiar Report" />
      <div className="container-fluid p-5" id="reportBg">
        <div className="row">
          <div className=" col-md-6 d-flex flex-column align-items-center justify-content-evenly">
            <ReportTitle
              title="Patient Details"
              setImage={setImage}
              value={0}
            />
            <ReportTitle
              title="Reliability Parameters"
              setImage={setImage}
              value={1}
            />
            <ReportTitle title="Raw Data" setImage={setImage} value={2} />
            <ReportTitle
              title="Total Deviation Plots"
              setImage={setImage}
              value={3}
            />
            <ReportTitle
              title="Software & Hardware Version"
              setImage={setImage}
              value={4}
            />
            <ReportTitle
              title="Pattern Deviation Plots"
              setImage={setImage}
              value={5}
            />
            <ReportTitle title="VFI | MD | PSD" setImage={setImage} value={6} />
            <ReportTitle
              title="Threshold Plots"
              setImage={setImage}
              value={7}
            />
            <div className="align-self-center mt-3">
              <button className="btn shadow rounded-pill btn-light  px-3  ">
                <a
                  href={sampleReport}
                  download
                  className="text-decoration-none text-blue lead pe-2"
                >
                  <i className="fas fa-file-download ms-3 me-2"></i>
                  Sample Report
                </a>
              </button>
            </div>
          </div>
          <div className=" col-md-6 text-center">
            <img
              loading="eager"
              src={Object.values(props.images[image])}
              alt=""
              className="img-fluid mt-5 mt-md-0"
              style={{
                maxHeight: "75vh",
              }}
            />
          </div>
        </div>
      </div>
      {/* Report ends */}
    </>
  );
};

const ReportTitle = (props) => {
  return (
    <>
      <div
        className="fs-3 w-100 text-center text-white "
        id="featureText"
        onMouseEnter={() => {
          props.setImage(props.value);
        }}
        onMouseMove={() => {
          props.setImage(props.value);
        }}
        onMouseDown={() => {
          props.setImage(props.value);
        }}
        onMouseLeave={() => {
          props.setImage(props.value);
        }}
        onClick={() => {
          props.setImage(props.value);
        }}
      >
        {props.title}
      </div>
    </>
  );
};
