import React, { useEffect, useState } from "react";
import { Layout } from "../layout/Layout";
import styles from "../assets/css/contact.module.css";
import { Link } from "react-router-dom";
import { Loader } from "./Loader";
import axios from "axios";
// import axios from "axios";
export const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [contact, setContact] = useState("");
  const [error, setError] = useState([false, ""]);

  const handleSubmit = () => {
    if (message.trim().length === 0 || name.trim().length === 0 || email.trim().length === 0 || contact.trim().length === 0) {
      setError([true, "Please fill all the fields!"]);
    } else {
     let data = new FormData()
     data.append('name',name);
     data.append('email',email);
     data.append('contact',contact);
     data.append('message',message);
      axios.post("https://alfaleus.in/email.php",data).then(res=>{
        if(res.data.trim() === "Mail Sent"){
          setError([false,"Thank you for your query! We will reach out to you very soon"])
        }else{
          setError([true,"Something went wrong please try again later."])
        }
        
      }).catch(err=>setError([true,"Something went wrong please try again later."]))
    }
  };

  const [laoding, setlaoding] = useState(true);

  useEffect(() => {
    setlaoding(false);
  }, []);

  return (
    <>
      {laoding ? (
        <Loader />
      ) : (
    <Layout>
      <div className={`${styles.contactImg} container-fluid `}>
        <div className={`row h-100 px-md-5 pt-3 `}>
          <div className="col-md-5  p-md-4  ">
            <p className="fs-1 fw-bold text-white mb-0">Contact us. </p>
            <div className="bg-white rounded-3 d-block p-3 mt-0">
              {error ? (
                <>
                  <p
                    className={`${
                      error[0] ? "text-danger" : "text-success"
                    } text-center`}
                  >
                    {error}
                  </p>
                </>
              ) : (
                <></>
              )}
              <input
                type="text"
                className="form-control border-3 border-dark form-control-sm"
                placeholder="Name"
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
              <input
                type="text"
                className="form-control border-3 border-dark form-control-sm mt-3"
                placeholder="Email ID"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
              <input
                type="text"
                className="form-control border-3 border-dark form-control-sm mt-3"
                placeholder="Phone No."
                onChange={(e) => {
                  setContact(e.target.value);
                }}
              />

              <textarea
                className="form-control border-3 border-dark form-control-sm mt-3"
                rows="3"
                placeholder="Type your message here..."
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
              ></textarea>

              <button
                className="btn btn-dark w-100 mt-3"
                onClick={handleSubmit}
              >
                Send Message
              </button>
            </div>
            <p className="fs-1 fw-bold text-white mb-0 mt-3">Join us. </p>
            <div className="bg-white rounded-3 d-block p-3 mt-0">
              <p>
                we are always on the look out for good talent. Send your resume
                to{" "}
                <Link to="mailto:careers@alfaleus.com" className="text-blue">
                  {" "}
                  careers@alfaleus.com
                </Link>{" "}
                if you think you're a good fit for our team
              </p>
            </div>
          </div>
        </div>
        <div className="row text-end mt-5 mt-md-0">
          <div className="col mb-3">
            <Link
              target="_blank"
              to="https://www.google.com/maps/place/Alfaleus+Tech+Pvt+Ltd/@17.5890339,78.1199229,15z/data=!4m6!3m5!1s0x3bcbf1a8dd2f91fb:0xb29aaa8d4c919d28!8m2!3d17.5890339!4d78.1199229!16s%2Fg%2F11sjd38wc7 "
              className="btn rounded-pill px-4 p-2 bg-white mx-3 shadow shake "
              style={{ bottom: 0, right: 0 }}
            >
              <i className="fas lead me-2 fa-map-marked-alt "></i>
              <span className="lead">Maps</span>
            </Link>
          </div>
        </div>
      </div>
      {/* <img src={contact} className="img-fluid" alt=""/> */}
    </Layout>
      )}
      </>
  );
};
