import React  from "react";
import { ButtonGroup } from "../Components";
import styles from "../../assets/css/home.module.css";
import Typewriter from "typewriter-effect";
import brochure from "../../assets/brochure/IVA+ brochure screen.pdf";

export const MyHero = (props) => {
  const types = props.typewriter;
  return (
    <>
      <div className={`container-fluid position-relative  m-0 p-0`}>
        <div className={` row  m-0 py-5 ${styles.myHeroImg}`}>
          <div className={`col-md-8 col-xl-5 fs-1 text-white p-xl-5 col-12`}>
            {props.label.visible ? (
              <>
                <div
                  className={`position-absolute bottom-0 start-0  px-3 mx-0 ${
                    props.label.subText ? "text-center" : "text-center px-1"
                  } `}
                  style={{
                    borderRadius: "0px 8px 8px 0px ",
                    backgroundColor: props.label.backgroundColor,
                    color: props.label.textcolor,
                  }}
                >
                  <span className="fs-6 fw-bold">{props.label.mainText}</span>
                  <p
                    className="fs-6 fw-bold"
                    style={{ color: props.label?.subTextColor }}
                  >
                    {props.label?.subText}{" "}
                  </p>
                </div>
              </>
            ) : (
              <></>
            )}
            <div className="d-flex justify-content-start align-items-start  flex-column  px-sm-5">
              <p className={` fw-bold`}>
                {props.typewriter ? (
                  <>
                    <Typewriter
                      options={{
                        strings: types,
                        autoStart: true,
                        loop: true,
                      }}
                    />
                  </>
                ) : (
                  <></>
                )}
                {props.title}
              </p>

              <p className={`fs-6 text-break text-md-start text-center`}>{props?.body}</p>

              {props.buttons ? (
                <>
                  <ButtonGroup brochure={brochure} />
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
