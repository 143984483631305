
export const ivaFeatures = [
    {
        head:"Model / Part no.",
        data:"Intelligent Vision Analyzer + (iVA+)",
    },
    {
        head:"Test Type",
        data:"10-2 | 24-2 | 30-2",
    },
    {
        head:"Screening Strategy",
        data:"TOP,  Supra Threshold",
    },
    {
        head:"Threshold Strategy",
        data:"Standard Threshold",
    },
    {
        head:"Stimulus  Color",
        data:"White on White",
    },
    {
        head:"Testing Time",
        data:"2 minutes - 8 minutes per eye",
    },
    {
        head:"Field of View",
        data:"Up to 60 degrees",
    },
    {
        head:"Reliability Parameters",
        data:"Fixation loss, False Positive",
    },
    {
        head:"Stimulus  Size",
        data:"Goldman Standard III, IV, V",
    },
    {
        head:"Stimulus  Duration",
        data:"200 milliseconds",
    },
    {
        head:"Stimulus Intensity",
        data:"Variable",
    },
    {
        head:"Smartphone",
        data:"Lenovo Tab M8",
    },
    {
        head:"Fixation Monitoring",
        data:"Gaze tracker / Heijl-Krakau",
    },
    {
        head:"Dimensions",
        data:"180 x 185 x 250 mm",
    },
    {
        head:"Battery",
        data:"up to 3 hours ",
    },
];
