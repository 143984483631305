import React, { useEffect, useState } from "react";
import { products } from "../assets/json/products";
import { Layout } from "../layout/Layout";

import {
  Hero,
  Accordian,
  ProductCard,
  ProductVideos,
  CarousalControls,
  AccordianDynamic,
  SectionHeading,
} from "./Components";
// Product Images

//Testimonial images
import testimonial1 from "../assets/images/testimonials/1.png";
import testimonial2 from "../assets/images/testimonials/2.png";
import testimonial3 from "../assets/images/testimonials/3.png";
import testimonial4 from "../assets/images/testimonials/4.png";
import { Loader } from "./Loader";

export const Home = (props) => {
  const collabTooltip = [
    
    ["Aravind Eye Hospital, Pondicherry"],
    ["Indian Institure of Technology , Hyderabad"],
    ["Department of Science and technology"],
    ["Ministry of Electronics and Information Technology"],
    ["Startup India"],
    ["Vellore Institure of Technology"],
    ["Birac"],
    ["Invest India"],
    ["Remidio"],
  ];
  const supportedByTooltip = [
    "Department of Science and Technology (DST)",
    "Ministry of Electronics and IT, Government of India",
    "Swissnex",
    "Vellore Institute of Technology(VIT), Vellore",
    "Centre for medical innovation and entrepreneurship,CMIE AIIMS, New Delhi.",
    "Bangalore Bioinnovation Center",
    "BIRAC",
    "Deshpande Startups.",
    "IKP Knowledge Park, Bangalore",
    "Research and Innovation Circle of Hyderabad (RICH)",
    "Sine, IIT Bombay",
    "Venture Center, Pune",
  ];

  function importAll(r) {
    let images = [];
    r.keys().map((item) => images.push({ [item.replace("./", "")]: r(item) }));
    return images;
  }

  const images = importAll(
    require.context("../assets/images/clients/", false, /\.(png|jpe?g|svg)$/)
  ).map((img, ind) => {
    return {
      img,
      tooltip: collabTooltip[ind],
    };
  });
  const supportImages = importAll(
    require.context(
      "../assets/images/supportedBy/",
      false,
      /\.(png|jpe?g|svg)$/
    )
  ).map((img, ind) => {
    return {
      img,
      tooltip: supportedByTooltip[ind],
    };
  });

  const publicationImages = importAll(
    require.context(
      "../assets/images/publications/",
      false,
      /\.(png|jpe?g|svg)$/
    )
  ).map((img, ind) => {
    return {
      img,
      tooltip: "",
    };
  });

  const customerImages = importAll(
    require.context("../assets/images/customers/", false, /\.(png|jpe?g|svg)$/)
  );

  const [laoding, setlaoding] = useState(true);

  useEffect(() => {
    setlaoding(false);
  }, []);

  return (
    <>
      {laoding ? (
        <Loader />
      ) : (
        <Layout sliderTitle="Awards &amp; Achievements" slider={true}>
          <Hero />
          <Accordian
            title="Associations &amp; Collaborations"
            id="clientsAccordian"
            images={images}
            tooltip={collabTooltip}
            childId="collapse"
          />

          {/* <MyCarousel data={data} title="Customer Testimonials" /> */}
          {/* <Slider/> */}
          <SectionHeading title={"Our Products"} />
          <div className="container">
            <div className="row d-flex justify-content-center">
              {products.map((data, ind) => (
                <ProductCard data={data} key={ind} />
              ))}
            </div>
          </div>

          <AccordianDynamic
            title="Clinical Publications"
            id="supportAccordian"
            images={publicationImages}
            childId="collapseThree"
            txt={[]}
          />

          <ProductVideos title="Impact We Created" />

          <SectionHeading title={"Our Customers"} />
          <CustomerImages images={customerImages} />

          <SectionHeading title={"Customer Testimonials"} />
          <Testimonials />
          <TestimonialSm />

          <Accordian
            title="Supported By"
            id="supportAccordian"
            images={supportImages}
            childId="collapseTwo"
          />
        </Layout>
      )}
    </>
  );
};

const Testimonials = () => (
  <>
    <div
      id="testimonialSlider1"
      className="carousel slide container d-none d-md-block"
    >
      <div className="carousel-indicators">
        <button
          type="button"
          data-bs-target="#testimonialSlider1"
          data-bs-slide-to="0"
          className="bg-black active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#testimonialSlider1"
          data-bs-slide-to="1"
          className="bg-black"
          aria-label="Slide 2"
        ></button>
      </div>
      <div className="carousel-inner px-lg-5">
        <div className={`carousel-item active `}>
          {/* <div className={`card rounded border border-dark p-5 ${styles.testimonialCard}`}> */}
          <div className="container ">
            <div className="row d-flex justify-content-center align-items-center pb-5 ">
              <div className="col-md-6 ">
                <img src={testimonial1} className="img-fluid" alt="" />
              </div>
              <div className="col-md-6 ">
                <img src={testimonial2} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className={`carousel-item `}>
          <div className="container ">
            <div className="row d-flex justify-content-center align-items-center pb-5 ">
              <div className="col-md-6 ">
                <img src={testimonial3} className="img-fluid" alt="" />
              </div>
              <div className="col-md-6 ">
                <img src={testimonial4} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <CarousalControls carousalId="testimonialSlider1" />
    </div>
  </>
);
const TestimonialSm = () => (
  <>
    <div id="testimonialSlider" className="carousel slide container d-md-none">
      <div className="carousel-indicators ">
        <button
          type="button"
          data-bs-target="#testimonialSlider"
          data-bs-slide-to="0"
          className="bg-black active"
          aria-current="true"
          aria-label="Slide 1"
        ></button>
        <button
          type="button"
          data-bs-target="#testimonialSlider"
          data-bs-slide-to="1"
          className="bg-black"
          aria-label="Slide 2"
        ></button>
        <button
          type="button"
          data-bs-target="#testimonialSlider"
          data-bs-slide-to="2"
          className="bg-black"
          aria-label="Slide 3"
        ></button>
        <button
          type="button"
          data-bs-target="#testimonialSlider"
          data-bs-slide-to="3"
          className="bg-black"
          aria-label="Slide 3"
        ></button>
      </div>
      <div className="carousel-inner">
        <div className={`carousel-item active `}>
          <div className="container ">
            <div className="row d-flex justify-content-center align-items-center pb-5 ">
              <div className="col-md-6 ">
                <img src={testimonial1} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className={`carousel-item  `}>
          <div className="container ">
            <div className="row d-flex justify-content-center align-items-center pb-5 ">
              <div className="col-md-6 ">
                <img src={testimonial2} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className={`carousel-item `}>
          <div className="container ">
            <div className="row d-flex justify-content-center align-items-center pb-5 ">
              <div className="col-md-6 ">
                <img src={testimonial3} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className={`carousel-item  `}>
          <div className="container ">
            <div className="row d-flex justify-content-center align-items-center py-sm-5 ">
              <div className="col-md-6 ">
                <img src={testimonial4} className="img-fluid" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#testimonialSlider"
        data-bs-slide="prev"
      >
        <span
          className="carousel-control-prev-icon bg-white shadow border border-black rounded-circle d-flex justify-content-center align-items-center"
          aria-hidden="true"
        >
          <i className="fas fa-caret-left text-black "></i>
        </span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next "
        type="button"
        data-bs-target="#testimonialSlider"
        data-bs-slide="next"
      >
        <span
          className="carousel-control-next-icon bg-white shadow border border-black rounded-circle d-flex justify-content-center align-items-center"
          aria-hidden="true"
        >
          <i className="fas fa-caret-right text-black "></i>
        </span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  </>
);

const CustomerImages = (props) => {
  const data = [
    "Command Hospital,<br/>Indian Army,<br/>Pune | Mumbai",
    "Carl Zeiss AG,<br/> Germany ",
    "Ajanta Pharma Limited,<br/>Mumbai",
    "Swami Vivekanand ,<br/> Medical Mission,<br/> Nagpur",
    "Dr. Parikshit Gogate,<br/>Ophthalmologist,<br/>Pune ",
    "Dr. Chandresh Parekh,<br/>Squint Specialist,<br/>Mumbai",
    "Dr. Rahul Achlerkar,<br/>Glaucoma specialist,<br/>Mumbai ",
    "Dr. John Davis Akkara,<br/>Glaucoma specialist,<br/>Kochi",
  ];
  return (
    <>
      <div className="container my-5">
        <div className="row">
          {props.images.map((img, ind) => (
            <CustomerCard
              img={img}
              data={data[ind]}
              ind={ind}
              key={ind}
              id={props.id}
              childId={props.childId}
            />
          ))}
        </div>
      </div>
    </>
  );
};

const CustomerCard = (props) => {
  const style = {
    aspectRatio: 1 / 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxHeight: "250px",
    margin: "auto",
    maxWidth: "100%",
    border: "2px solid #4B4B4B",
  };
  return (
    <>
      <div className="col-md-3 col-6">
        <div
          className="card rounded-25 shadow  text-center p-3 mb-3"
          style={style}
        >
          <img
            src={Object.values(props.img)}
            alt={Object.keys(props.img)}
            key={props.ind}
            style={{
              maxWidth: "90%",
            }}
          />
        </div>
        <p
          className=" fs-4 text-center text-break "
          dangerouslySetInnerHTML={{ __html: props.data }}
        ></p>
      </div>
    </>
  );
};
