import React, { useEffect, useState } from "react";
import { Layout } from "../../layout/Layout";
import "../../assets/css/about.css";
import pico from "../../assets/images/products/G24K-Enterprise-02.png";
import { SectionHeading, Table, TR } from "../Components";
import brochure from "../../assets/brochure/LVA brochure screen.pdf";
import { Link } from "react-router-dom";

import feature1 from "../../assets/images/openlva/features/feature1.png";
import feature2 from "../../assets/images/openlva/features/feature2.png";
import feature3 from "../../assets/images/openlva/features/feature3.png";
import feature4 from "../../assets/images/openlva/features/feature4.png";
// fact  images

import fact1 from "../../assets/images/openlva/factcards/1.png";
import fact2 from "../../assets/images/openlva/factcards/2.png";
import fact3 from "../../assets/images/openlva/factcards/3.png";
import fact4 from "../../assets/images/openlva/factcards/4.png";
import fact5 from "../../assets/images/openlva/factcards/5.png";
import fact6 from "../../assets/images/openlva/factcards/6.png";

// feature carousal images
import filter1 from "../../assets/images/openlva/filterCarousel/Normal.png";
import filter2 from "../../assets/images/openlva/filterCarousel/Greyscale.png";
import filter3 from "../../assets/images/openlva/filterCarousel/Black on white.png";
import filter4 from "../../assets/images/openlva/filterCarousel/White on black.png";
import filter5 from "../../assets/images/openlva/filterCarousel/fisheye.png";
import filter6 from "../../assets/images/openlva/filterCarousel/Fisheye-red.png";
import filter7 from "../../assets/images/openlva/filterCarousel/Fisheye-yellow.png";

import openlvaSection from "../../assets/images/openlva/openlva-section.png";
import openlvaFeature from "../../assets/images/openlva/openlva-facts.png";

import openlvaFeatureSm from "../../assets/images/openlva/openlva-factsSm.png";
import { Loader } from "../Loader";

export const OpenLVA = () => {
  // function importAll(r) {
  //   let images = [];
  //   r.keys().map((item) => images.push({ [item.replace("./", "")]: r(item) }));
  //   return images;
  // }
  // const images = importAll(
  //   require.context("../../assets/brochure/", false, /\.(pdf)$/)
  // );
  // console.log(images);
  const [laoding, setlaoding] = useState(true);

  useEffect(() => {
    setlaoding(false);
  }, []);

  return (
    <>
      {laoding ? (
        <Loader />
      ) : (
    <Layout image={true} brochure={brochure} slider={false} sliderTitle="">
      {/* Hero */}
      <div className="container-fluid px-md-5 pt-5 " id="aboutHero">
        <div className="row d-flex justify-content-center align-items-center">
          <div className="col-md-6 text-center text-md-start p-3">
            {/* <img src={logo} alt="" className="img-fluid mb-3" /> */}
            <p className="display-5 fw-bolder text-black">
              Open Low Vision Assist
            </p>
            <p className="me-md-5 pe-md-5">
              Virtual Reality (VR) based and clinically validated low vision
              assistive aid that helps in improving the visual acuity of low
              vision patients thereby helping them in their day to day
              activities.
            </p>
            <Link
              to="/contact"
              className="btn text-white  rounded-pill px-4 "
              style={{ backgroundColor: "#255E8B" }}
            >
              Test our beta
            </Link>
            <br />

            <a
              href={brochure}
              download
              className="btn  rounded-pill btn-primary-outline btn-outline-light mt-3 px-3"
              style={{ color: "#255E8B", border: "1px solid #255E8B" }}
            >
              <i className="fas fa-file-download mx-3"></i>
              Brochure
            </a>
          </div>
          <div className="col-md-5 p-3 text-end">
            <img src={pico} className="img-fluid" alt="" />
          </div>
        </div>
      </div>
      {/* Hero ends */}

      <p
        className="w-100 text-center mt-5 "
        style={{
          fontStyle: "normal",
          fontWeight: 900,
          fontSize: "64px",
          lineHeight: "96px",
          color: "#255E8B",
        }}
      >
        Launching soon !
      </p>
      {/* feature cards start */}
      <div className="container  ">
        <div className="row  mb-5 ">
          <div className="col-lg-6 d-lg-block d-flex justify-content-center  my-3">
            <FeatureCard img={feature1} title="Independence">
              LVA gives you the joy of being independent by making you stand
              amongst the crowd like any other normal vision.
            </FeatureCard>
          </div>
          <div className="col-lg-6 d-lg-block d-flex justify-content-center   my-3">
            <FeatureCard img={feature2} title="Adaptive features">
              The user can easily set the brightness, lens and filters as per
              requirements to have a better visual clarity without straining the
              eyes.
            </FeatureCard>
          </div>
          <div className="col-lg-6 d-lg-block d-flex justify-content-center  my-3">
            <FeatureCard img={feature3} title="Visibility">
              LVA combines the far and near vision needs of the user in one
              device thereby reducing the need of different devices for
              different viewing.
            </FeatureCard>
          </div>
          <div className="col-lg-6 d-lg-block d-flex justify-content-center  my-3">
            <FeatureCard img={feature4} title="Improved posture">
              LVA improves posture while using a device, allowing users to rest
              and perform tasks with minimal interruption.
            </FeatureCard>
          </div>
        </div>
      </div>

      {/* featrure cards end */}

      {/* filter carousal */}
      <FilterCarousel />
      {/* filter carousal ends */}

      {/* versatility starts  */}
      <div className="container-fluid ps-lg-5 py-5">
        <div className="row ">
          <div className="col-lg-6 order-lg-1 order-2 p-lg-5 text-center text-lg-start ">
            <p className="ms-3 fs-2 fw-bold mt-3">Versatility</p>
            <p className="ms-3 fs-4 pe-5">
              Open LVA is a highly versatile low vision device designed to
              assist individuals with serious eye conditions. With unmatched
              mobility, ease of use, and advanced visual acuity ranging from
              20/60 to 20/1400.
            </p>
            <p className="ms-3 fs-4 pe-5">
              Open LVA takes sight enhancement to a new level. Experience a
              whole new world of visual clarity and freedom with Open LVA.
            </p>
          </div>
          <div className="col-lg-6  d-flex justify-content-center align-items-center mt-5 mt-lg-0 text-lg-end p-0 order-lg-2 order-1">
            <img src={openlvaSection} alt="" className="img-fluid" />
          </div>
        </div>
      </div>

      {/* versatility ends */}

      <div className="container-fluid text-white bg-blue text-capitalize pb-4">
        <SectionHeading title="Take control of your vision" />
      </div>

      {/* fact cards  start  */}
      <div className="container py-5">
        <div className="row mt-5">
          <div className="col-md-6 my-3">
            <FactCard img={fact1}>
              Fits comfortably over prescription glasses for even greater
              enhanced vision
            </FactCard>
          </div>
          <div className="col-md-6 my-3">
            <FactCard img={fact2}>
              See tiny details with freeze, save, and 16x zoom
            </FactCard>
          </div>
          <div className="col-md-6 my-3">
            <FactCard img={fact3}>
              Move between TV, reading, indoor & custom vision modes
            </FactCard>
          </div>
          <div className="col-md-6 my-3">
            <FactCard img={fact4}>
              Share the moment and maximize your screen viewing pleasure.
            </FactCard>
          </div>
          <div className="col-md-6 my-3">
            <FactCard img={fact5}>Easy to Use and Learn</FactCard>
          </div>
          <div className="col-md-6 my-3">
            <FactCard img={fact6}>
              Empowering people with low vision and legal blindness to see new
              possibilities.
            </FactCard>
          </div>
        </div>
      </div>
      {/* fact cards end   */}

      {/* disorders start  */}
      <SectionHeading title="Disorders Recommended" />
      <img
        src={openlvaFeature}
        alt=""
        className="img-fluid w-100 mt-4 d-none d-lg-block"
      />
      <img
        src={openlvaFeatureSm}
        alt=""
        className="img-fluid w-100 mt-4 d-lg-none"
      />
      {/* disorders ends  */}

      {/* tech specs start  */}
      <Table title="Technical Specifications">
        <TR title="Model / Part no.">Open LVA</TR>
        <TR title="Data Storage">128GB</TR>
        <TR title="CPU">Qualcomm Snapdragon 835</TR>
        <TR title="Weight">298g(w/o band), 490g(total)</TR>
        <TR title="Refresh Rate">75Hz</TR>
        <TR title="External Camera">1 RGB Camera</TR>
        <TR title="Tracking">3DoF</TR>
        <TR title="Stimulus Interval">1200-1500 ms</TR>
        <TR title="Stimulus Interval">
          Macular Degeneration <br /> Diabetic Retinopathy <br /> Glaucoma{" "}
          <br /> Cataract
        </TR>
        <TR title="Lenses / Filters">
          Fish Eye <br /> Fish Eye (Red) <br /> Fish Eye (Yellow) <br /> White
          and Black <br /> Black AND White <br /> Grey Scale <br /> Inline Lens
        </TR>
        <TR title="Special Features">
          Screen Capture and Pan, Raycast Magnify
        </TR>
      </Table>
      {/* tech spec ends  */}
    </Layout>
      )}
      </>
  );
};

const FeatureCard = (props) => {
  return (
    <>
      <div className="card  rounded-25 p-lg-4 py-4 shadow h-100">
        <div className="d-md-flex ps-3 text-center text-md-start">
          <img
            src={props.img}
            alt=""
            style={{ aspectRatio: "3/2", objectFit: "contain" }}
            className="me-3  ms-3  img-fluid align-self-center "
          />
          <div className="align-self-center ">
            <p className="fs-4 fw-semibold text-blue mb-0 w-100  me-5 ">
              {props.title}
            </p>
            <p className=" w-100 mb-0 ">{props.children}</p>
          </div>
        </div>
      </div>
    </>
  );
};

const FilterCarousel = () => {
  const title = [
    "No Filter",
    "Greyscale",
    "Black on white",
    "White on black",
    "Fisheye",
    "Fisheye Red",
    "Fisheye Yellow",
  ];
  const [index, setIndex] = useState(0);

  let nextItem = 0;
  let prevItem = 0;
  prevItem = index - 1 > 0 ? index - 1 : index - 1 < 0 ? title.length - 1 : 0;

  if (index >= 0 && index < title.length - 1) nextItem = index + 1;
  else nextItem = 0;

  return (
    <>
      <p className="fs-1 text-center mt-5 mb-3 fw-bold">
        Open LVA <span className="fw-normal">({title[index]})</span>
      </p>
      <div id="openlva" className="carousel slide ">
        <div className="carousel-inner">
          <div className="carousel-item active">
            <img
              src={filter1}
              className="d-block w-100"
              alt="alfaleus open LVA filter lens "
            />
          </div>
          <div className="carousel-item ">
            <img
              src={filter2}
              className="d-block w-100"
              alt="alfaleus open LVA filter lens "
            />
          </div>
          <div className="carousel-item ">
            <img
              src={filter3}
              className="d-block w-100"
              alt="alfaleus open LVA filter lens "
            />
          </div>
          <div className="carousel-item ">
            <img
              src={filter4}
              className="d-block w-100"
              alt="alfaleus open LVA filter lens "
            />
          </div>
          <div className="carousel-item ">
            <img
              src={filter5}
              className="d-block w-100"
              alt="alfaleus open LVA filter lens "
            />
          </div>
          <div className="carousel-item ">
            <img
              src={filter6}
              className="d-block w-100"
              alt="alfaleus open LVA filter lens "
            />
          </div>
          <div className="carousel-item ">
            <img
              src={filter7}
              className="d-block w-100"
              alt="alfaleus open LVA filter lens"
            />
          </div>
        </div>
        <>
          <button
            className="carousel-control-prev "
            type="button"
            onClick={() => {
              setIndex(prevItem);
            }}
            data-bs-target={`#openlva`}
            data-bs-slide="prev"
          >
            <span
              className="bg-white p-3 shadow border border-black rounded-circle d-flex justify-content-center align-items-center"
              aria-hidden="true"
            >
              <i className="fas fa-caret-left text-black "></i>
            </span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next "
            type="button"
            data-bs-target={`#openlva`}
            onClick={() => {
              setIndex(nextItem);
            }}
            data-bs-slide="next"
          >
            <span
              className=" bg-white p-3 shadow border border-black rounded-circle d-flex justify-content-center align-items-center"
              aria-hidden="true"
            >
              <i className="fas fa-caret-right text-black "></i>
            </span>
            <span className="visually-hidden">Next</span>
          </button>
        </>
      </div>
    </>
  );
};

const FactCard = (props) => {
  return (
    <>
      <div
        className="card rounded-25 h-100 shadow p-3"
        style={{
          height: "218",
        }}
      >
        <div className="d-lg-flex text-center text-lg-start h-100 ">
          <img
            src={props.img}
            alt=""
            className=" img-fluid align-self-center"
          />
          <p className="fs-4 text-center w-100 align-self-center ">
            {props.children}
          </p>
        </div>
      </div>
    </>
  );
};
