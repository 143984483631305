import React, { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../assets/logo.png";
import bwlogo from "../assets/logo_bw.png";
import "../assets/css/Layout.css";
import { ButtonGroup, CarousalControls } from "../components/Components";

import brain from "../assets/images/footer_under_logo.png";

export const Layout = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function importAll(r) {
    let images = [];
    r.keys().map((item) => images.push({ [item.replace("./", "")]: r(item) }));
    return images;
  }
  const sliderImages = importAll(
    require.context(
      "../assets/images/Footerslider/",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  return (
    <>
      <Navbar />
      <div className="container-fluid p-0 m-0">{props.children}</div>

      {props?.slider ? (
        <>
          <BSCarousel
            brochure={props.brochure}
            slides={sliderImages}
            image={props.image}
            sliderTitle={props.sliderTitle}
            key="key1"
          />
        </>
      ) : (
        <>
          {props.image ? (
            <>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12 d-none footer-img d-lg-flex justify-content-end align-items-end p-5 flex-column ">
                    
                    <Link
                      to="/contact"
                      className="btn shadow rounded-pill text-white mx-5 my-2 px-5"
                      style={{
                        backgroundColor: "#255E8B",
                      }}
                    >
                      Book a Demo
                    </Link>
                  </div>
                  <div className="col-12 d-lg-none text-center p-0">
                    <div className="footer-img">&nbsp;</div>
                    <Link
                      to="/contact"
                      className="btn shadow rounded-pill text-white mx-5 my-2 px-5"
                      style={{
                        backgroundColor: "#255E8B",
                      }}
                    >
                      Book a Demo
                    </Link>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </>
      )}
      <Footer />
    </>
  );
};

const Navbar = () => (
  <nav className="navbar navbar-expand-lg bg-white shadow p-4">
    <div className="container py-2">
      <Link to="/" className="text-decoration-none text-black">
        <img
          src={logo}
          alt=""
          style={{
            height: "41px",
          }}
        />
      </Link>
      <Link
        to="/"
        className="text-decoration-none text-black mx-3 d-none d-md-block"
      >
        <span className="lead fw-bold">Alfaleus Technology</span>
        <div>Making Eyecare Accessible</div>
      </Link>
      <button
        className="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <NavLink className="nav-link  " aria-current="page" to="/">
              Home
            </NavLink>
          </li>
          <li className="nav-item dropdown">
            <NavLink
              className="nav-link dropdown-toggle"
              role="button"
              to="/product/iva"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Products
            </NavLink>
            <ul className="dropdown-menu">
              <li>
                <NavLink className="dropdown-item" to="/product/iva">
                  iVA Intelligent Vision Analyzer Plus (iVA+)
                </NavLink>
              </li>
              <li>
                <NavLink className="dropdown-item" to="/product/c3fa">
                  Compact Field Analyzer (C3FA)
                </NavLink>
              </li>
              <li>
                <NavLink className="dropdown-item" to="/product/openlva">
                  Open LVA
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link" to="/about">
              About
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink className="nav-link " to="/contact">
              Contact Us
            </NavLink>
          </li>
        </ul>
      </div>
    </div>
  </nav>
);

const Footer = () => {
  const d = new Date();
  let year = d.getFullYear();

  const data_col_1 = [
    {
      text: "Instagram",
      link: "https://www.instagram.com/alfaleustech/?igshid=MjkzY2Y1YTY%3D",
    },
    {
      text: "LinkedIn",
      link: "https://www.linkedin.com/company/alfaleustech/",
    },
    { text: "Facebook", link: "https://www.facebook.com/alfaleus" },
    {
      text: "Youtube",
      link: "https://www.youtube.com/channel/UCCafyp0rSpC3VM73fImzrzg ",
    },
    { text: "Twitter", link: "/twitter" },
    { text: "Medium", link: "/medium" },
  ];
  const data_col_2 = [
    { text: "Home", link: "/" },
    { text: "Products", link: "/product/iva" },
    { text: "About", link: "/about" },
    { text: "Blogs", link: "/blogs" },
    { text: "Careers", link: "/contact" },
    { text: "Contact Us", link: "/contact" },
  ];
  return (
    <>
      <Link
        target="_blank"
        to="https://api.whatsapp.com/send?phone=919655042547&text=Hello I wanna know more about your products and services."
        className="float text-decoration-none"
      >
        <i className="fab fa-whatsapp display-5"></i>
      </Link>
      <div className="container-fluid  pt-5 bg-black  ">
        <footer className="row p-3">
          <div className="col-lg-3 mb-3 d-flex justify-content-center align-items-center text-white flex-column">
            <Link to="/">
              <img src={bwlogo} alt="" className="img-fluid mb-3" />
            </Link>
            <Link to="/" className="text-decoration-none text-white">
              <p className="fs-4 fw-bold  mb-0">Alfaleus Technology</p>
            </Link>
            <Link to="/" className="text-decoration-none text-white">
              <span className="fs-5 mt-0 fw-lighter ">
                Making Eye Care Accessible
              </span>
            </Link>
            <img src={brain} className="align-self-end  px-5 mt-2" alt="" />
          </div>

          <div className="col-lg-3 gx-5 mb-3 text-white">
            <h5 className="fw-bold">Registered Office</h5>
            <p className="text-secondary">
              II Floor, 654, Vivek Vihar,New Sanganare Road, Jaipur, Rajasthan,
              India 302019.
            </p>
          </div>
          <div className="col-lg-3 gx-5 text-white">
            <h5 className="mb-0 fw-bold">Head Office</h5>
            <p className="text-secondary mb-0">
              E-1, Technology Research Park,
              <br /> IIT-Hyderabad, Sangareddy,
              <br /> Kandi, Telangana, India 502285.
            </p>
            <p className="mb-0">
              <span className="fw-bold">Phone: </span>
              <Link
                className="text-secondary text-decoration-none hover-link"
                to="tel:919655042547"
              >
                +91 - 9655042547{" "}
              </Link>
            </p>
            <p className="">
              <span className="fw-bold">Email: </span>
              <Link
                className="text-secondary text-decoration-none hover-link"
                to="mailto:info@alfaleus.com"
              >
                info@alfaleus.com{" "}
              </Link>
            </p>
          </div>
          <div className="col-lg-2  mb-3">
            <div className="container-fluid">
              <div className="row">
                <div className="d-flex justify-content-evenly px-2 text-secondary">
                  <Lists data={data_col_1} align={true} />
                  <Lists data={data_col_2} />
                </div>
              </div>
            </div>
          </div>
        </footer>
        <div className="row">
          <div className="col">
            <p className="text-secondary text-center w-100">
              Copyright © {year} Alfaleus Technology Pvt. Ltd.{" "}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

const Lists = ({ data, align }) => (
  <ul className={`nav flex-column text-secondary ${align ? "text-end" : ""}`}>
    {data.map((data, ind) => (
      <li key={ind} className="nav-item ">
        <Link
          className="text-secondary hover-link text-decoration-none"
          to={data.link}
        >
          {data.text}
        </Link>
      </li>
    ))}
  </ul>
);

export const BSCarousel = ({ slides, image, sliderTitle, brochure }) => {
  return (
    <>
      <p className="fs-1 fw-bold text-center w-100 mb-5 pt-5">{sliderTitle}</p>
      <div
        id="carouselExample"
        className="carousel slide "
        data-bs-ride="carousel"
      >
        <div className="carousel-inner">
          {slides.map((slide, ind) => (
            <>
              <div
                className={`carousel-item ${ind === 0 ? "active" : ""}`}
                key={Math.random()}
              >
                <img
                  src={Object.values(slide)}
                  className="w-100"
                  alt={Object.keys(slide)}
                />
              </div>
            </>
          ))}
        </div>
        <CarousalControls carousalId="carouselExample" />
      </div>

      {image ? (
        <>
          <div className="container-fluid">
            <div className="row">
              <div className="col-12 footer-img d-flex justify-content-center align-items-center flex-column ">
                <ButtonGroup brochure={brochure} />
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
