import styles from "../assets/css/home.module.css";
// import whiteLogo from "../assets/images/white_logo.png";
// import oldMan from "../assets/images/old_man_with_c3fa.png";
import React, { Component, useState } from "react";
import ReactCardCarousel from "react-card-carousel";
import { Link } from "react-router-dom";
import NumberScroller from "react-number-scroller";
import Typewriter from "typewriter-effect";

export const Card = ({
  dynamic,
  total,
  hidden,
  tooltip,
  img,
  ind,
  id,
  childId,
}) => {
  const style = {
    aspectRatio: 1 / 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    maxHeight: "250px",
    maxWidth: "100%",
  };

  return (
    <>
      {dynamic ? (
        <div className=" col-md-3 col-6 p-3 ">
          {total > ind + hidden ? (
            <>
              <div className="accordion-body">
                <div
                  className="card rounded-25 shadow border border-dark text-center p-3 mb-3"
                  style={style}
                >
                  <img
                    src={Object.values(img)}
                    alt={Object.keys(img)}
                    key={ind}
                    style={{
                      maxWidth: "90%",
                    }}
                  />
                </div>
                <p className=" fs-4 text-center text-break">
                  {Object.keys(img)[0].slice(
                    0,
                    Object.keys(img)[0].lastIndexOf(".")
                  )}
                </p>
              </div>
            </>
          ) : (
            <>
              <div
                id={childId}
                className="accordion-collapse collapse "
                aria-labelledby="headingOne"
                data-bs-parent={`#${id}`}
              >
                <div
                  className="card rounded-25 shadow border border-dark text-center p-3 mb-3"
                  style={style}
                >
                  <img
                    src={Object.values(img)}
                    alt={Object.keys(img)}
                    key={ind}
                    style={{
                      maxWidth: "90%",
                    }}
                  />
                </div>
                <p className=" fs-4 text-center text-break">
                  {Object.keys(img)[0].slice(
                    0,
                    Object.keys(img)[0].lastIndexOf(".")
                  )}
                </p>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className=" col-md-3 col-6 p-3 ">
          {ind < 8 ? (
            <>
              <div className="accordion-body">
                <div
                  className="card rounded-25 shadow border border-dark text-center p-3"
                  style={style}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={tooltip}
                >
                  <img
                    src={Object.values(img)}
                    alt={Object.keys(img)}
                    key={ind}
                    style={{
                      maxWidth: "90%",
                    }}
                  />
                </div>
              </div>
            </>
          ) : (
            <>
              <div
                id={childId}
                className="accordion-collapse collapse "
                aria-labelledby="headingOne"
                data-bs-parent={`#${id}`}
              >
                <div
                  className="card rounded-25 shadow border border-dark text-center p-3"
                  style={style}
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  title={tooltip}
                >
                  <img
                    src={Object.values(img)}
                    alt={Object.keys(img)}
                    key={ind}
                    className="img-fluid"
                  />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export const Hero = () => {
  return (
    <>
      <div className={`container-fluid  m-0 p-0`}>
        <div className={`row  m-0 p-0 ${styles.heroImg}`}>
          <div className={`col-12 fs-1 fw-bold text-white p-sm-5`}>
            <div className="d-md-flex justify-content-start  align-items-center h-100  px-sm-5  d-none">
              <span className={` display-4  fw-bold ${styles.tshadow}`}>
                We make eye care <br />
                <Typewriter
                  options={{
                    strings: ["Accessible ", " Affordable ", " Comfortable"],
                    autoStart: true,
                    loop: true,
                  }}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="d-md-none text-center my-5">
        <span className={` display-4 fw-bold  `}>
          We make eye care
          <Typewriter
            options={{
              strings: ["Accessible ", " Affordable ", " Comfortable"],
              autoStart: true,
              loop: true,
            }}
          />
        </span>
      </div>
    </>
  );
};

export const Images = (props) => {
  const total = props.images;
  const hidden =
    total.length % 4 > 0 ? total.slice(0 - (total.length % 4)).length : 0;
  // console.log(props.tooltip.split('|'))
  return (
    <>
      {props.images.map((img, ind) => (
        <Card
          img={img.img}
          hidden={hidden}
          total={total.length}
          dynamic={props.dynamic}
          tooltip={img.tooltip}
          ind={ind}
          key={ind}
          id={props.id}
          childId={props.childId}
        />
      ))}
    </>
  );
};
export const ImagesCopy = (props) => {
  const total = props.images;
  const hidden =
    total.length % 4 > 0 ? total.slice(0 - (total.length % 4)).length : 0;
  // console.log(props.tooltip.split('|'))
  return (
    <>
      {props.images.map((img, ind) => (
        <Card
          img={img}
          hidden={hidden}
          total={total.length}
          dynamic={props.dynamic}
          tooltip={props.tooltip}
          ind={ind}
          key={ind}
          id={props.id}
          childId={props.childId}
        />
      ))}
    </>
  );
};

export const SectionHeading = (props) => (
  <>
    <div className="container pt-5 ">
      <div className="row">
        <div className="col-12 text-center">
          <p className="fs-1 fw-bold">{props.title}</p>
        </div>
      </div>
    </div>
  </>
);

export const Accordian = (props) => {
  const [down, setdown] = useState(true);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex justify-content-center align-items-center my-5">
            <span className="fs-1 fw-bold text-center ">{props.title}</span>
            <button
              className="btn shake border-0 "
              data-bs-toggle="collapse"
              data-bs-target={`#${props.childId}`}
              aria-expanded="false"
              aria-controls={props.id}
              onClick={() => setdown(!down)}
            >
              <i className={`fas fs-4 fa-caret-${down ? "down" : "up"}`}></i>
            </button>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row pb-md-5 px-md-5">
          <Images
            context={props.context}
            childId={props.childId}
            id={props.id}
            images={props.images}
            tooltip={props.tooltip}
          />
        </div>
      </div>
    </>
  );
};
export const AccordianDynamic = (props) => {
  const [down, setdown] = useState(true);
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex justify-content-center align-items-center my-5">
            <span className="fs-1 fw-bold text-center ">{props.title}</span>
            <button
              className="btn shake border-0 "
              data-bs-toggle="collapse"
              data-bs-target={`#${props.childId}`}
              aria-expanded="false"
              aria-controls={props.id}
              onClick={() => setdown(!down)}
            >
              <i className={`fs-4 fas fa-caret-${down ? "down" : "up"}`}></i>
            </button>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="row pb-md-5 px-xl-5">
          <Images
            dynamic={true}
            context={props.context}
            childId={props.childId}
            id={props.id}
            images={props.images}
          />
        </div>
      </div>
    </>
  );
};

export const Testimonial = (props) => {
  return (
    <>
      <div className={`card pb-3 rounded `}>
        <div className="card-body p-5">
          <p className="card-text">{props.data.testimonial}</p>
        </div>
        <span className="fw-bold mx-4 fw-4">{props.data.author}</span>
        <span className=" mx-4 fw-4">{props.data.position}</span>
        <span className=" mx-4 fw-4">{props.data.hospital}</span>
      </div>
    </>
  );
};

export class MyCarousel extends Component {
  static get CONTAINER_STYLE() {
    return {
      position: "relative",
      height: "50vh",

      padding: 0,
      margin: 0,
    };
  }

  constructor() {
    super();
    this.state = { ind: 0 };
  }

  render() {
    // this.setState({ind:this.Carousel.getCurrentIndex()})

    return (
      <>
        <div className="container">
          <div className="row p-0 m-0 ">
            <p className="fs-1 fw-bold w-100 text-center">{this.props.title}</p>
            <div
              style={MyCarousel.CONTAINER_STYLE}
              className="col-12 m-0 p-0 bg-transparent "
            >
              <button onClick={() => this.Carousal.goToNext()}>s</button>
              <ReactCardCarousel
                spread="wide"
                autoplay={true}
                autoplay_speed={2500}
              >
                {this.props.data.map((data, ind) => (
                  <span className={styles.testCard}>
                    <Testimonial data={data} key={ind} />
                  </span>
                ))}
              </ReactCardCarousel>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export const ProductCard = ({ data }) => (
  <>
    <div className="col-lg-11 my-3 ">
      <div className="card position-relative border border-dark-subtle shadow container rounded-25 p-md-5">
        <div className="row p-3">
          <div className="col-md-7">
            <p className="fs-2 fw-bold">{data.name}</p>
            <p className="fs-4 my-md-5 text-justify">{data.description}</p>
            <Link
              preventScrollReset={false}
              to={`${data.link}/#top`}
              className="btn rounded-pill align-bottom"
              style={{
                backgroundColor: "#255E8B",
                color: "#fff",
                width: "12.75rem",
              }}
            >
              <span className="lead">Know More...</span>
            </Link>
          </div>
          <div className="col-md-5 d-flex justify-content-center align-items-center mx-0 px-0 mt-md-0 mt-5 p-md-0">
            <img src={data.image} alt="iVA+" className=" img-fluid" />
          </div>
          {data.label.visible ? (
            <>
              <div
                className={`position-absolute bottom-0 end-0 p-1  mb-5 mx-0 border border-dark  ${
                  data.label.subText ? "text-center" : "text-center p-3"
                } `}
                style={{
                  width: "241px",
                  borderRadius: "8px 0px 0px 8px",
                  backgroundColor: data.label.backgroundColor,
                  color: data.label.textcolor,
                  opacity:0.7
                }}
              >
                <span className="fs-4 fw-bold">{data.label.mainText}</span>
                <br />
                <p
                  className="fs-6 fw-bold w-100 text-end px-4  mb-0"
                  style={{ color: data.label?.subTextColor }}
                >
                  {data.label?.subText}
                </p>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  </>
);

export const ProductVideos = (props) => {
  return (
    <>
      <div className="container-fluid my-3">
        <div className="row mt-3">
          <div className="col-md-12 text-center">
            <p className="fs-1 fw-bold">{props.title}</p>
          </div>
        </div>

        <div className="row mt-4 text-center ">
          <div className="col-4 text-break col-md-4">
            <h2 className=" fw-bold mb-0 pb-0 text-center">
              <NumberScroller to={50000} from={49500} timeout={1} /> +
            </h2>
            <h4 className="text-muted text-center w-100 ">Lives Impacted</h4>
          </div>
          <div className="col-4 text-break col-md-4">
            <h2 className=" fw-bold mb-0 pb-0">
              <NumberScroller to={150} timeout={1000} /> +
            </h2>
            <h4 className="text-muted text-center w-100 ">Trusted Doctors</h4>
          </div>
          <div className="col-4 text-break col-md-4">
            <h2 className="text-center  fw-bold mb-0 pb-0">
              <NumberScroller to={4} timeout={1000} /> +
            </h2>
            <h4 className="text-muted text-center w-100">Countries</h4>
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-12 d-flex justify-content-center align-items-center">
            <iframe
              width="560"
              height="315"
              src={
                props.link
                  ? props.link
                  : "https://www.youtube.com/embed/MPbURIU4H8k?controls=0"
              }
              title="YouTube video player"
              allowFullScreen
              className="rounded"
            ></iframe>
          </div>
        </div>
      </div>
    </>
  );
};

export const Table = (props) => (
  <>
    <div className="container-fluid  p-md-5">
      <div className="row ">
        <div className="col-12 text-center">
          <SectionHeading title={props.title} />
        </div>
      </div>
      <div className="row px-md-5 py-4 px-md-0">
        <div className="container px-md-3 ">{props.children}</div>
      </div>
    </div>
  </>
);
export const TR = (props) => (
  <>
    <div
      className="row mb-2 "
      style={{
        border: "1px solid #C8E2EE",
        borderRadius: "20px",
      }}
    >
      <div
        className="col-6 p-0 m-0 bg-sky d-flex justify-content-start align-items-center"
        style={{
          borderRadius: "20px",
        }}
      >
        <p className="px-3 p-0 m-0 fs-5 p-2 ">{props.title}</p>
      </div>
      <div className="col-6 ">
        <p className="p-0 m-0 fs-5 p-md-2 ps-3 ">{props.children}</p>
      </div>
    </div>
  </>
);

export const ButtonGroup = (props) => {
  return (
    <>
      <Link
        to="/contact"
        className="btn shadow rounded-pill text-white my-3 px-3"
        style={{
          backgroundColor: "#255E8B",
        }}
      >
        Book a Demo
      </Link>
      <a
        href={props.brochure}
        download
        className="btn shadow rounded-pill btn-primary-outline btn-outline-light px-3"
      >
        <i className="fas fa-file-download mx-3"></i>
        Brochure
      </a>
    </>
  );
};

export const FeatureCardCarousel = (props) => (
  <>
    <div id={props.carousalId} className="carousel slide d-none d-lg-block ">
      <div className="carousel-inner pb-4">
        {props.data.map((slide, ind) => (
          <>
            <div
              className={`carousel-item ${ind === 0 ? "active" : ""}`}
              key={ind}
            >
              <FeatureCard data={slide} />
            </div>
          </>
        ))}
        <CarousalControls carousalId={props.carousalId} />
      </div>
    </div>

    <div id={`${props.carousalId}1`} className="carousel slide d-lg-none ">
      <div className="carousel-inner ">
        {props.data.map((slide, ind) => {
          return (
            <div key={ind}>
              {slide.map((dx, ind) => {
                return (
                  <>
                    <div className={`carousel-item `} key={ind}>
                      <FeatureCard data={[dx]} key={ind} />
                    </div>
                  </>
                );
              })}
            </div>
          );
        })}
        <CarousalControls carousalId={`${props.carousalId}1`} />
      </div>
    </div>
  </>
);

const FeatureCard = (props) => (
  <>
    <div className="container my-5 ">
      <div className="row mx-3">
        {props.data.map((props, ind) => (
          <div className=" col-lg-4 mt-3 " key={ind}>
            <div className="card h-100 rounded-25 shadow p-4 ">
              <p className="fs-4 mb-0 fw-semibold text-blue">{props.title}</p>
              <p className="fs-6 ">{props.body}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  </>
);

export const CarousalControls = (props) => {
  return (
    <>
      <button
        className="carousel-control-prev h-75"
        type="button"
        onClick={() => {
          props.setIndex(props.prevIndex);
        }}
        data-bs-target={`#${props.carousalId}`}
        data-bs-slide="prev"
      >
        <span
          className="bg-white shadow border border-black rounded-circle d-flex justify-content-center align-items-center"
          id="carousalControls"
          aria-hidden="true"
        >
          <i className="fas fa-caret-left text-black "></i>
        </span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next h-75"
        type="button"
        data-bs-target={`#${props.carousalId}`}
        onClick={() => {
          props.setIndex(props.nextIndex);
        }}
        data-bs-slide="next"
      >
        <span
          className=" bg-white shadow border border-black rounded-circle d-flex justify-content-center align-items-center"
          aria-hidden="true"
          id="carousalControlsRight"
        >
          <i className="fas fa-caret-right text-black "></i>
        </span>
        <span className="visually-hidden">Next</span>
      </button>
    </>
  );
};
