import React, { useEffect, useState } from "react";
import { Layout } from "../layout/Layout";
import logo from "../assets/logo.png";
import journey from "../assets/images/our_journey.png";
import z0 from "../assets/images/timeline-z-0.png";
import "../assets/css/about.css";
import { Link } from "react-router-dom";
import { Loader } from "./Loader";

export const About = () => {
  function importAll(r) {
    let images = [];
    r.keys().map((item) => images.push({ [item.replace("./", "")]: r(item) }));
    return images;
  }
  const images = importAll(
    require.context("../assets/images/team/", false, /\.(png|jpe?g|svg)$/)
  );
  // console.log(images);
  const [scrollAmount, setScrollAmount] = useState(0);

  const handleScrollLeft = () => {
    // console.log(scrollAmount);
    scrollAmount === -2800
      ? setScrollAmount(0)
      : setScrollAmount(scrollAmount - 25);
  };

  const [laoding, setlaoding] = useState(true);

  useEffect(() => {
    setlaoding(false);
  }, []);

  return (
    <>
      {laoding ? (
        <Loader />
      ) : (
    <Layout image={false}>
      <div className="container-fluid p-5" id="aboutHero">
        <div className="row d-flex justify-content-center align-items-center py-5">
          <div className="col-lg-9 col-md-6  col-12 text-center p-3 ">
            <img
              src={logo}
              alt=""
              className=" mb-3"
              style={{
                width: "263px",
              }}
            />
            <p className="fs-2 fw-bold text-dark mt-4">
              We work collaboratively to design and develop cutting-edge
              products and services that make eye care more accessible,
              affordable, and effective for everyone. We are excited to be at
              the forefront of the eye care technology industry and look forward
              to making a positive impact on the world.
            </p>
            <p className="display-6 fw-bold text-black mt-4">
              We are Alfaleus!
            </p>
          </div>
        </div>
        <div className="row ">
          <div className="col-lg-3 col-md-6 offset-lg-3 ">
            <TeamDesc
              image={images[0]}
              name="Sandal Kotawala"
              position="CEO / Founder"
              link="https://www.linkedin.com/in/sandal-kotawala/"
            />
          </div>
          <div className="col-lg-3 col-md-6 text-center">
            <TeamDesc
              image={images[1]}
              name="Dr. P. Arulmozhivarman"
              position="Technical Director"
              link="https://www.linkedin.com/in/arulmozhivarman-pachiyappan-86028835/"
            />
          </div>
        </div>
      </div>
      <div className="container card shadow rounded border border-dark px-lg-5 py-5 ">
        <p className="w-100 text-center fs-4 fw-bold  ">
          Mentors / Technical Advisors
        </p>
        <div className="row">
          <div className="col-lg-4 col-md-6  ">
            <TeamDesc
              image={images[2]}
              name="Dr. Venkatesh"
              position="Chief Medical Officer"
              hospital="Aravind Eye Hospital"
              link="https://aravind.org/doctors/dr-r-venkatesh/#1549962531438-7f035b6c-57b7e01f-ad4f"
            />
          </div>
          <div className="col-lg-4 col-md-6 ">
            <TeamDesc
              image={images[3]}
              name="Dr. Swati Upadhyay "
              link="https://www.linkedin.com/in/swati-upadhyaya-62185262/"
              position="HOD, Neuro Ophthalmology,"
              hospital="Aravind Eye Hospital"
            />
          </div>
          <div className="col-lg-4 col-md-6 ">
            <TeamDesc
              image={images[4]}
              name="Dr. Anand Sivaraman"
              position="CEO / Founder,"
              link="https://www.linkedin.com/in/anand-sivaraman-1542266/"
              hospital=" Remidio"
            />
          </div>

          <div className="col-lg-4 col-md-6 ">
            <TeamDesc
              image={images[5]}
              name="Dr. Balachandran"
              link="https://www.linkedin.com/in/balachandrana/"
              position="General Manger,"
              hospital=" VIT TBI"
            />
          </div>
          <div className="col-lg-4 col-md-6 ">
            <TeamDesc
              image={images[6]}
              name="Dr. S. Priya"
              position="Head of Neuro Ophthalmology"
              hospital="Aravind Eye Hospital"
              link="https://aravind.org/doctors/dr-s-priya/#1549962531438-7f035b6c-57b76424-0904"
            />
          </div>
          <div className="col-lg-4 col-md-6 ">
            <TeamDesc
              image={images[7]}
              name="Dr. Jitendra Kumar"
              position="Managing Director,"
              hospital="Bangalore Bioinnovation Centre"
              link="https://www.linkedin.com/in/piscean/"
            />
          </div>
        </div>
      </div>
      <div className="container card shadow rounded border border-dark px-lg-5 py-5 mt-lg-5 mt-3 ">
        <p className="w-100 text-center fs-4 fw-bold  ">The Team</p>
        <div className="row">
          <div className="col-lg-3 col-md-6  ">
            <TeamDesc
              image={images[8]}
              name="Saurabh Bhatt"
              position="Project Manager"
              link="https://www.linkedin.com/in/saurabh-bhatt-4545539b/"
            />
          </div>
          <div className="col-lg-3 col-md-6  ">
            <TeamDesc
              image={images[9]}
              name="Nandha Kumar"
              position="Senior Developer"
              link="https://www.linkedin.com/in/nandha-kumar-1495a817b/"
            />
          </div>
          <div className="col-lg-3 col-md-6  ">
            <TeamDesc
              image={images[10]}
              name="Priyanka Chourasia"
              position="Optometris"
              link="https://www.linkedin.com/in/priyanka-chourasia-2218b2186/"
            />
          </div>
          <div className="col-lg-3 col-md-6 ">
            <TeamDesc
              image={images[11]}
              name="Kiran Shukla"
              position="Regional Sales Manager"
              link="https://www.linkedin.com/in/kiran-shukla-8381a4232/"
            />
          </div>
          <div className="col-lg-4 col-md-6  ">
            <TeamDesc
              text="text-center text-md-end"
              image={images[12]}
              name="Hardhik .P.V.V"
              position="Electronics Intern"
              link="https://www.linkedin.com/in/hardhik-pvv-7152521a6/"
            />
          </div>
          <div className="col-lg-4 col-md-6  ">
            <TeamDesc
              image={images[13]}
              name="Abu Thahir Amee"
              position="Industrial Design Intern"
              link="https://www.linkedin.com/in/abu-thahir-ameer-52467967/"
            />
          </div>
          <div className="col-lg-4 col-md-6  ">
            <TeamDesc
              text="text-center text-md-start"
              image={images[14]}
              name="Deepikaa Balaji"
              position="Research fellow Intern"
              link="https://www.linkedin.com/in/deepikaa-balaji/"
            />
          </div>
        </div>
      </div>
      {/* Our journey starts */}
      <div className="container-fluid py-5 mt-3">
        <div className="row">
          <div className="col-12">
            <p className="fs-1 text-center fw-bold">Our Journey</p>
          </div>
          <div className="col-12 py-5 position-relative">
            <img
              src={z0}
              alt=""
              height={"350rem"}
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 1,
                pointerEvents: "none",
                width: "100%",
              }}
              className="d-none d-md-block"
            />
            <div id="overflowbox">
              <img
                src={journey}
                alt=""
                height={"350rem"}
                style={{
                  transform: `translateX(${scrollAmount}px)`,
                  marginLeft: "35rem",
                  marginRight: "35rem",
                }}
                className="scrollable-image"
                // onMouseDown={(event) => handleScrollRight()}
                onMouseMove={(event) => handleScrollLeft()}
              />
            </div>
          </div>
        </div>
      </div>
      {/* our journey ends  */}
      {/* join us */}
      <div className="container-fluid " id="joinUs">
        <div className="row ">
          <div className="col-12 text-center p-5 d-flex justify-content-center flex-column ">
            <p className="fs-1 fw-bold">Join Us</p>

            <p className="text-center fs-2 ">
              At Alfaleus we are dedicated to making healthcare more accessible
              with the power of design and technology and we are looking for
              candidates who can help us take this to the next level through
              their sheer determination and hard work. We are always on the look
              out for good talent. Send your resume to careers@alfaleus.com if
              you think you're a good fit for our team
            </p>
            <p className="text-center fs-2    ">
              We are always on the look out for good talent. <br /> Send your
              resume to{" "}
              <Link className="text-blue" to="mailto:careers@alfaleus.com">
                careers@alfaleus.com
              </Link>{" "}
              if you think you're a good <br />
              fit for our team
            </p>
          </div>
        </div>
      </div>
    </Layout>
    )}
    </>
  );
};

const TeamDesc = (props) => {
  const [shadow, setShadow] = useState(false);
  return (
    <div className={props.text ? props.text : "text-center "}>
      <Link
        to={props.link}
        target="_blank"
        className="text-decoration-none shake "
        onMouseEnter={() => setShadow(true)}
        onMouseLeave={() => setShadow(false)}
      >
        <img
          src={Object.values(props.image)}
          alt=""
          className={` rounded-circle ${shadow ? "shadow-lg border" : ""}`}
          style={{
            aspectRatio: "1/1",
            height: "15.25rem",
          }}
        />
        <p
          className="fs-3 fw-bold mb-0 mt-3"
          style={{
            color: "#255E8B",
          }}
        >
          {props.name}
        </p>
        <p
          className="m-0  lh-sm text-black "
          style={{
            fontSize: "22px",
          }}
        >
          {props.position}
        </p>
        <p
          className="m-0 lh-sm text-black "
          style={{
            fontSize: "22px",
          }}
        >
          {props?.hospital}
        </p>
      </Link>
    </div>
  );
};
