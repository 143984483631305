
import logo from "./assets/logo.png";
import { Routes, Route, Link } from "react-router-dom";
import { About } from "./components/About";
import { Home } from "./components/Home";
import { Contact } from "./components/Contact";
import { OpenLVA } from "./components/products/OpenLVA";
import { C3FA } from "./components/products/C3FA";
import "./App.css";
import { IVAPlus } from "./components/products/IVAplus";



function App() {

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/product/openlva" element={<OpenLVA />} />
        <Route path="/product/c3fa" element={<C3FA />} />
        <Route path="/product/iva" element={<IVAPlus />} />
        <Route path="*" element={<ComingSoon />} />
      </Routes>
    
    </>
  );
}

export default App;

const ComingSoon = () => (
  <div id="aboutHero">
    <div className="container-fluid">
      <div className={`row pt-5 d-flex justify-content-center align-items-center `} >
        <img src={logo} className="" style={{
          width:"263px",
          height:"109px"
        }} alt="" />
      </div>
    </div>
    <div
      className="d-flex align-items-center justify-content-center  flex-column"
      style={{ height: "60vh" }}
    >
      <p className="display-6 text-blue text-center">Coming up soon!</p>
      <Link to="/">Go back to home</Link>
    </div>
  </div>
);
